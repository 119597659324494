.primary {
  &:global(.ant-tooltip) {
    max-width: 450px;
  }

  :global(.ant-tooltip-placement-right, .ant-tooltip-placement-rightTop, .ant-tooltip-placement-rightBottom) {
    padding-left: 0px;
  }

  :global(.ant-tooltip-inner) {
    padding: 0;
    border-radius: 4px;
    box-shadow: none;
    background-color: transparent;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: -8px;
      top: 4px;
      width: 0;
      height: 0;
      border-width: 8px 0 8px 8px;
      border-color: transparent transparent transparent #FFFFFF;
      border-style: solid;
    }
  }

  &:global(.ant-tooltip-placement-rightBottom .ant-tooltip-inner) {
    &::after {
      top: initial;
      right: initial;
      bottom: 8px;
      left: -8px;
      transform: rotate(180deg);
    }
  }

  &:global(.ant-tooltip-placement-topLeft .ant-tooltip-inner) {
    &::after {
      transform: rotate(90deg);
      top: initial;
      bottom: -12px;
      left: 8px;
      right: initial;
    }
  }

  &:global(.ant-tooltip-placement-rightTop .ant-tooltip-arrow) {
    top: 20px;
  }

  &:global(.ant-tooltip-placement-leftTop .ant-tooltip-arrow) {
    right: 31px;
  }

  :global(.ant-tooltip-arrow-content::before) {
    background: #fff;
  }
}
